<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row :gutter="20">
        <el-form :inline="true" :model="queryInfo" class="demo-form-inline">
          <el-form-item label="活动名称">
            <el-input placeholder="请输入活动名称" clearable v-model="queryInfo.name" @clear="getLogList" @change="current">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getLogList">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
          </el-form-item>
          <el-form-item v-if="caozuoquanxian">
            <el-button type="warning" @click="add_dialoag" icon="el-icon-plus" :disabled="tianjia">添加小程序充值送</el-button>
          </el-form-item>
          <el-form-item v-if="tianjiachangzhanyouhuiquan">
            <el-button type="danger" @click="zhandiantianjia" icon="el-icon-plus"
              :disabled="tianjia_chang">添加场站优惠券</el-button>
          </el-form-item>
          <el-form-item v-if="tianjiaxinrensong">
            <el-button type="success" @click="newtianjia" icon="el-icon-plus" :disabled="tianjia_new">添加新人送</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- table表格区域  :row-class-name="tableRowClassName" :row-class-name="selectedHighlight"-->
      <el-table style="width: 100%" :data="logList" border empty-text="暂无数据">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" label="活动名称" prop="name"></el-table-column>
        <el-table-column align="center" label="活动类型" prop="type" width="120">
          <template scope="scope">
            <el-tag
              :type="scope.row.type == 0 ? 'info' : scope.row.type == 1 ? 'success' : scope.row.type == 2 ? 'error' : scope.row.type == 3 ? 'warning' : ''">
              {{ scope.row.typeName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="开始时间" prop="startTime" width="100">
          <template scope="scope">
            <span v-if="scope.row.startTime != null">{{ scope.row.startTime }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="到期时间" prop="endTime" width="100">
          <template scope="scope">
            <span v-if="scope.row.endTime != null">{{ scope.row.endTime }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="生效类型" prop="effectiveType" width="120">
          <template scope="scope">
            <el-tag :type="scope.row.effectiveType == 0 ? 'success' : 'warning'">
              {{ scope.row.effectiveType == 0 ? '即时生效' : '指定生效' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡券生效时间" prop="effectiveDate" width="120">
          <template scope="scope">
            <span v-if="scope.row.effectiveDate != null">{{ scope.row.effectiveDate }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="优惠类型" prop="discountType" width="120">
          <template scope="scope">
            <span v-if="scope.row.discountType != null">{{ scope.row.discountTypeName }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="管理员" prop="adminName" width="180"></el-table-column>
        <!-- <el-table-column align="center" label="绑定Vin" prop="bindvin" width="80">
          <template scope="scope">
            <el-tag :type="scope.row.bindvin == 1 ? 'success' : 'warning'">
              {{ scope.row.bindvin == 1 ? '是' : '否' }}
            </el-tag>
          </template>
        </el-table-column> -->
        <el-table-column align="center" label="状态" prop="status" width="100">
          <template scope="scope">
            <el-tag
              :type="scope.row.status == 0 ? 'info' : scope.row.status == 1 ? 'success' : scope.row.status == 2 ? 'error' : 'warning'">
              {{ scope.row.statusName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作" v-if="bianjideng" width="320">
          <template slot-scope="scope">
            <!-- 需要画删除线 -->
            <!-- 作废 -->
            <el-button size="small" type="danger" @click="kaipiao(scope.row)"
              :disabled="scope.row.status == 0 ? false : true">作废</el-button>
            <!-- 停用 -->
            <el-button size="small" type="success" @click="tingyong(scope.row)"
              :disabled="scope.row.status == 3 || scope.row.status == 0 ? false : true">{{ scope.row.status == 0 ? '停用'
        :
        '启用' }}</el-button>
            <!-- 编辑 -->
            <el-button size="small" type="primary" @click="bianji(scope.row)" icon="el-icon-edit"
              v-if="scope.row.status == 0 || scope.row.status == 3 ? true : false">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 添加新人送 -->
    <el-dialog :title="xinren" :visible.sync="tianjia_new_dialoag" width="60%">
      <el-form :model="newinfo" :rules="newinfoRules" ref="newinfoRef" label-width="110px">
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;"></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="value3">
          <el-date-picker v-model="value3" :disabled="checked" @change="time_all3" type="datetimerange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
          <el-checkbox style="margin-left: 50px;" v-model="checked" @change="beixuan" :disabled="dis">长期</el-checkbox>
        </el-form-item>
        <el-divider content-position="center">新人送方案</el-divider>
        <el-form-item>
          <div v-for="(item, index) in obj" :key="index" style="display: flex;flex-direction: row;
          list-style: none;
          ">
            <li style="flex:1;">
              <el-form-item label="优惠券金额(元)" prop="paramValue" style="padding-bottom: 10px;">
                <el-input-number size="mini" v-model="item.paramValue" controls-position="right" :step="1"
                  label="赠送金额"></el-input-number>
              </el-form-item>
            </li>
            <li style="flex:1;">
              <el-form-item label="订单金额(元)" prop="paramKey" style="padding-bottom: 10px;padding-right: 4px;">
                <el-input-number size="mini" v-model="item.paramKey" :step="1" controls-position="right"
                  label="充值金额"></el-input-number>
              </el-form-item>
            </li>
            <li style="flex:1;">
              <el-form-item label="数量(张)" prop="num" style="padding-bottom: 10px;">
                <el-input-number size="mini" v-model="item.num" controls-position="right" :step="1"
                  label="券数量"></el-input-number>
              </el-form-item>
            </li>
            <li style="flex:1;">
              <el-form-item label="时效(天)" prop="day" style="padding-bottom: 10px;">
                <el-input-number size="mini" v-model="item.day" controls-position="right" :step="1"
                  label="时效"></el-input-number>
              </el-form-item>
            </li>
          </div>
          <el-button style="margin-left: 40%;" @click="add_obj" v-if="obj.length >= 6 ? false : true">新增方案</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ax">取 消</el-button>
        <el-button type="primary" v-debounce="queding">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 场站优惠券 -->
    <el-dialog :title="acruce" :visible.sync="zhandian">
      <el-form :model="youhui" label-width="80px" :rules="youhuiRules" ref="youhuiRef">
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="youhui.name" autocomplete="off" style="width: 80%;"></el-input>
        </el-form-item>
        <div class="ceil" style="display: flex;flex-direction: row;">
          <span style="color: red;padding-top: 12px;width: 10px;">*</span>
          <el-form-item label="活动时间" prop="value2" style="margin-left: -10px;">
            <el-date-picker v-model="value2" @change="time_all2" type="datetimerange" range-separator="至"
              start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']" end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
        </div>
        <!-- youhui.stationIds -->
        <el-form-item prop="stationIds" label="* 活动电站">
          <el-select v-model="activeStationIds" multiple filterable placeholder="请选择你的优惠、赠送站点" @change="station_change">
            <el-option v-for="item in station_options" :key="item.ids" :label="item.stationName" :value="item.ids">
            </el-option>
          </el-select>
        </el-form-item>
        <el-divider content-position="center">场站优惠方案</el-divider>
        <el-form-item>
          <div v-for="(item, index) in objValues" :key="index">
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="电量(度)" prop="paramKey" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.paramKey" :min="0" :precision="2" :step="1" controls-position="right"
                    label="电量(元)"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="金额(元)" prop="paramValue" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.paramValue" controls-position="right" :precision="2" :step="1" :min="0"
                    label="金额(元)"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="天数(天)" prop="day" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.day" controls-position="right" :step="1" :min="0"
                    label="天数(天)"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-button style="margin-left: 40%;" @click="addobjs">新增方案</el-button>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="生效方式" required>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-button @click="liji">立即生效</el-button>
            </el-col>
            <el-col :span="10">
              <el-button @click="zhiding" :type="iconsss">指定生效时间</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <div v-if="liji_flag">
          <el-form-item label="指定日期" prop="effectiveDate">
            <el-date-picker v-model="youhui.effectiveDate" type="datetime" placeholder="选择日期" @blur="bul_event"
              default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="qx">取 消</el-button>
        <el-button type="primary" v-debounce="ok">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 小程序充值送 -->
    <el-dialog :title="title" :visible.sync="diaload">
      <el-form :model="addinfo" :rules="addinfoRules" ref="addinfoRef" label-width="80px">
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="addinfo.name" autocomplete="off" style="width: 80%;"></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="value1">
          <el-date-picker v-model="value1" @change="time_all" type="datetimerange" range-separator="至"
            start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']" end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-divider content-position="center">充值赠送方案</el-divider>
        <el-form-item>
          <div v-for="(item, index) in objValue" :key="index">
            <el-row :gutter="24">
              <el-col :span="10">
                <el-form-item label="充值金额" prop="paramKey" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.paramKey" :precision="2" :step="0.01" controls-position="right"
                    label="充值金额"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赠送金额" prop="paramValue" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.paramValue" controls-position="right" :precision="2" :step="0.01"
                    label="赠送金额"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-button style="margin-left: 40%;" @click="addobj">新增方案</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="qx_anniu">取 消</el-button>
        <el-button type="primary" v-debounce="addLogList">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'logWorld',
  data() {
    return {
      activeStationIds: [],
      nvis: 0,
      current_id: 0,
      dis: false,
      checked: false,
      xinren: '添加新人送',
      more_cheked: true,
      tianjiaxinrensong: false,
      tianjia_new_dialoag: false,
      tianjia_new: false,
      tianjia: false,
      shuju: false,
      tianjia_chang: false,
      shuju_chang: false,
      tianjiachangzhanyouhuiquan: false,
      bianjideng: false,
      flag: false,
      liji_flag: false,
      value1: ['', ''],
      value2: ['', ''],
      value3: ['', ''],
      zhandian: false,
      // 场站优惠券
      youhui: {
        bindvin: null,
        content: '',
        createTime: '',
        discountType: 1,
        discountTypeName: '',
        effectiveDate: '',
        effectiveType: null,
        endTime: '',
        id: null,
        name: '',
        requirement2: '',
        startTime: '',
        stationIds: '',
        status: null,
        statusName: '',
        stopTime: '',
        type: 1,
        typeName: '',
        updateTime: '',
        userGroupId: null,
        adminId: '',
      },
      station_options: [],
      stationIds: null,
      zhanji: [],
      num: null,
      valuing: null,
      title: '添加小程序充值送',
      add_dia: false,
      caozuoquanxian: false,
      add_mini: false,
      code: '',
      number: '',
      row: null,
      options_active: [
        {
          label: '充值送',
          type: 0
        },
        {
          label: '优惠券',
          type: 1
        },
        {
          label: '新人送',
          type: 2
        },
        {
          label: '折扣',
          type: 3
        },
        {
          label: '其他活动',
          type: 4
        }
      ],
      options_youhui: [
        {
          label: '送钱',
          discountType: 0
        },
        {
          label: '送券/活动类型',
          discountType: 1
        },
        {
          label: '服务费',
          discountType: 2
        },
        {
          label: '消费',
          discountType: 3
        }
      ],
      checkindex: [],
      settlementForm: {},
      // 查询参数对象
      queryInfo: {
        page: 1,
        limit: 8,
        discountType: null,
        type: null,
        name: '',
        userGroupId: null,
        stationIds: window.sessionStorage.getItem('stationIds'),
        adminId: window.sessionStorage.getItem('adminId'),
      },
      newinfo: {
        timeType: 0,
        discountType: null,
        discountTypeName: '',
        effectiveDate: '',
        effectiveType: null,
        endTime: '',
        id: null,
        name: '',
        requirement1: '',
        requirement2: '',
        startTime: '',
        status: null,
        stopTime: '',
        statusName: '',
        type: null,
        typeName: '',
        userGroupId: null,
        stationIds: window.sessionStorage.getItem('adminId'),
        adminName: '',
        content: '',
        adminId: ''
      },
      addinfo: {
        timeType: 0,
        bindvin: null,
        content: '',
        adminName: '',
        createTime: '',
        discountType: 0,
        discountTypeName: '',
        effectiveDate: '',
        effectiveType: null,
        endTime: '',
        id: null,
        name: '',
        requirement1: '',
        requirement2: '',
        startTime: '',
        stationIds: window.sessionStorage.getItem('adminId'),
        status: 0,
        statusName: '',
        stopTime: '',
        type: 0,
        typeName: '',
        updateTime: '',
        userGroupId: null,
        adminId: ''
      },
      obj: [],
      objValue: [],
      objValues: [],
      addinfoRules: {
        name: [
          { required: true, message: '活动名称不能为空', trigger: 'blur' }
        ],
      },
      newinfoRules: {
        name: [
          { required: true, message: '活动名称不能为空', trigger: 'blur' }
        ],
      },
      youhuiRules: {
        name: [
          { required: true, message: '活动名称不能为空', trigger: 'blur' }
        ],
      },
      // 商品列表
      logList: [],
      chooseLogList: [],
      // 总数据条数
      total: 0,
      value: '',
      ids: '',
      diaload: false,
      seIndex: null,
      newIndex: null,
      xin: '',
      dis: false,
      icons: '',
      iconsss: '',
      acruce: '添加场站优惠券',
      timeType: 0,
      fag: false,
    }
  },
  created() {
    caozuo(149).then(res => {
      if (window.sessionStorage.getItem('roleId') == 5) {
        this.caozuoquanxian = res;
      }
    });
    caozuo(150).then(res => {
      if (window.sessionStorage.getItem('roleId') == 5) {
        this.bianjideng = res;
      }
    });
    caozuo(164).then(res => {
      if (window.sessionStorage.getItem('roleId') == 5) {
        this.tianjiachangzhanyouhuiquan = res;
      }
    });
    caozuo(168).then(res => {
      if (window.sessionStorage.getItem('roleId') == 5) {
        this.tianjiaxinrensong = res;
      }
    });
    this.station_options = [];
    this.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '0,1,2'
      }
    }).then(resb => {
      if (resb.data.code == 0) {
        resb.data.data.forEach(element => {
          this.station_options.push({
            ids: element.id,
            stationName: element.stationName
          })
        });
      }
    });
    this.getLogList()
  },
  methods: {
    current() {
      this.queryInfo.page = 1;
    },
    // 依旧还是指定生效，这个有问题
    station_change(e) {
      this.youhui.stationIds = e.join(',')
    },
    chongzhi() {
      this.queryInfo = {
        page: 1,
        limit: 8,
        discountType: null,
        type: null,
        name: '',
        userGroupId: null,
        stationIds: window.sessionStorage.getItem('stationIds'),
        adminId: window.sessionStorage.getItem('adminId'),
      };
      this.getLogList();
    },
    // 取消小程序充值送
    qx_anniu() {
      this.diaload = false;
      if (this.title == '编辑小程序充值送') {
        this.logList.forEach(item => {
          if (item.id == this.current_id) return item.status = 3;
        })
      }
    },
    // 取消添加场站优惠券
    qx() {
      this.youhui = {};
      this.value2 = ['', ''];
      this.objValues = [];
      this.activeStationIds = [];
      this.zhandian = false;
      this.liji_flag = false;
      this.iconsss = '';
      if (this.title == '编辑场站优惠券') {
        this.logList.forEach(item => {
          if (item.id == this.current_id) return item.status = 3;
        })
      }
    },
    // 取消添加新人送
    ax() {
      this.newinfo.startTime = '';
      this.newinfo.endTime = '';
      this.value3 = [];
      this.tianjia_new_dialoag = false;
      if (this.title == '编辑新人送') {
        this.logList.forEach(item => {
          if (item.id == this.current_id) return item.status = 3;
        })
      }

    },
    // 确定添加新人充值送
    queding() {
      let that = this;
      that.$refs.newinfoRef.validate(async valid => {
        if (!valid) return
        that.newinfo.discountType = 1;
        that.newinfo.preferentialType = 4;
        that.newinfo.adminId = window.sessionStorage.getItem('adminId') * 1;
        if (that.checked == false && that.value3.length == 0) return that.$message.error('请选择你的优惠时间');
        if (that.newinfo.discountTypeName) return that.$message.error('请输入您的活动名称')
        if (that.obj.length < 1) return that.$message.error('请点击新增方案添加您的优惠方案')
        // that.obj.forEach(item => {
        //   if (item.paramKey == 0 || item.paramValue == 0 || item.day == 0||item.num==0) return this.$message.error('优惠券金额、订单金额、数量、时效不得为0')
        // })
        that.newinfo.requirement1 = JSON.stringify(that.obj);
        if (that.xinren == '添加新人送') {
          that.newinfo.type = 2;
          that.newinfo.status = 0;
          that.$http.post('preferential/addPreferential', that.newinfo).then(res => {
            if (res.data.code == 0) {
              that.$message.success('添加新人送成功');
              that.getLogList();
              that.tianjia_new_dialoag = false;
              that.tianjia_new = false;
            } else {
              that.tianjia_new = false;
              that.$message.warning(res.data.msg);
            }
          })
        } else {
          that.$http.post('preferential/updPreferential', that.newinfo).then(res => {
            if (res.data.code == 0) {
              that.$message.success('编辑新人送成功');
              that.getLogList();
              that.tianjia_new_dialoag = false;
              that.tianjia_new = false;
            } else {
              that.tianjia_new = false;
              that.$message.warning('编辑新人送失败');
            }
          })
        }
        that.tianjia_new_dialoag = false;

      })
    },
    //新人送 多选框
    beixuan(e) {
      this.more_cheked = e;
      this.newinfo.effectiveType = 0;
      if (this.more_cheked == true) return this.newinfo.timeType = 1;
    },
    newtianjia() {
      this.tianjia_new_dialoag = true;
      this.xinren = '添加新人送';
      this.checked = false;
      this.newinfo = {};
      this.value3 = [];
      this.obj = []
    },
    // 新人送赠送方案
    add_obj() {
      this.obj.push({
        paramKey: 0,
        paramValue: 0,
        day: 0,
        num: 0,
      })
    },
    // 场站优惠券方案
    addobjs() {
      this.objValues.push({
        paramKey: 0,
        paramValue: 0,
        day: 0,
      })
    },
    // 小程序充值送方案
    addobj() {
      this.objValue.push({
        paramKey: 0,
        paramValue: 0,
      })
    },
    //添加场站优惠券
    zhandiantianjia() {
      this.zhandian = true;
      this.objValues = [];
      this.youhui = {};
      this.activeStationIds = [];
      this.iconsss = '';
      this.value2 = ['', ''];
      this.liji_flag = false;
      this.acruce = '添加场站优惠券';
    },
    // 立即执行活动
    liji() {
      this.liji_flag = false;
      this.youhui.effectiveType = 0;
    },
    // 指定日期
    zhiding() {
      this.liji_flag = true;
      this.youhui.effectiveType = 1;
    },
    // 确定添加场站优惠券
    ok() {
      this.youhui.timeType = 0;
      this.youhui.requirement2 = '';

      this.$refs.youhuiRef.validate(async valid => {
        if (!valid) return;
        this.youhui.status = 0;
        this.youhui.type = 1;
        this.youhui.adminId = window.sessionStorage.getItem('adminId') * 1;
        if (this.activeStationIds.length == 0) return this.$message.error('请选择你的活动场站');
        if (this.youhui.name.length == 0 || this.youhui.startTime.length == 0 || this.youhui.endTime.length == 0 || this.objValues.length < 1) return this.$message.error('请添加场站优惠券信息')
        this.objValues.forEach(item => {
          if (item.paramKey == 0 || item.paramValue == 0 || item.day == 0) return this.$message.error('场站电量、金额、优惠天数不得为0')
          this.fag = true;
        })
        this.meth()
        this.liji_flag = false;
        this.zhandian = false;
      })
    },
    async meth() {
      let cdn1 = '';
      let cdn2 = '';
      let cdn3 = '';
      if (this.fag == false) return;
      if (this.liji_flag == true) {
        if (this.youhui.effectiveDate == undefined) return this.$message.error('请选择你的生效时间');
        if (this.youhui.effectiveDate !== undefined && this.youhui.effectiveDate.length == 0) return this.$message.error('请选择你的生效时间');
        if (this.youhui.effectiveDate.length > 0) {
          cdn1 = new Date(this.youhui.startTime)
          cdn2 = new Date(this.youhui.endTime)
          cdn3 = new Date(this.youhui.effectiveDate);
          if (cdn3 < cdn1 || cdn3 > cdn2) return this.youhui.effectiveDate = this.youhui.startTime
        }
      };
      if (this.acruce == '添加场站优惠券') {
        this.youhui.requirement2 = JSON.stringify(this.objValues);
        await this.$http.post('preferential/addPreferential', this.youhui).then(res => {
          if (res.data.code == 0) {
            this.$message.success('添加场站优惠券成功');
            this.getLogList();
            this.zhandian = false;
          } else {
            this.$message.warning('添加场站优惠券失败');
          }
        })
        this.liji_flag = false;
        this.zhandian = false;
      } else {
        this.acruce = '编辑场站优惠券';
        this.youhui.requirement2 = JSON.stringify(this.objValues);
        this.$http.post('preferential/updPreferential', this.youhui).then(res => {
          if (res.data.code == 0) {
            this.$message.success('编辑场站优惠券成功');
            this.getLogList();
            this.zhandian = false;
          } else {
            this.$message.warning('编辑场站优惠券失败');
          }
        })
      }
    },
    // 指定日期
    bul_event() {
      let abc = '';
      let bac = '';
      let cab = '';
      if (this.activeStationIds.length == 0) return this.$message.error('请先添加场站优惠券的活动场站');
      // if (typeof (this.youhui.stationIds) == "object") { this.youhui.stationIds = Object.values(this.youhui.stationIds).join(',') };
      if (this.value2 == null) return this.$message.error('请先添加场站优惠券的活动时间');
      if (this.youhui.startTime.length !== 0 && this.youhui.endTime.length !== 0) {
        if (this.youhui.effectiveDate.length !== 0) {
          abc = this.youhui.startTime.split(' ')[0];
          bac = this.youhui.endTime.split(' ')[0];
          cab = this.youhui.effectiveDate.split(' ')[0];
          if (cab > bac || abc > cab || cab == bac) {
            this.youhui.effectiveDate = '';
            this.$message.error('指定生效时间错误，请重新选择');
          }
          if (cab == abc) return this.youhui.effectiveType = 0

        }
      }

    },
    // 指定生效
    zhidingshengxiao() {
      this.flag = true;
      this.addinfo.effectiveType = 1;
    },
    // 立即生效
    lijishengxiao() {
      this.flag = false;
      this.addinfo.effectiveType = 0;
    },
    // 时间选择器
    time_all(e) {
      if (e.length > 0) {
        if (e[0] == e[1]) {
          this.$message.error('起始时间不得为同一天！');
          this.addinfo.startTime = ''
          this.addinfo.endTime = ''
        } else {
          this.addinfo.startTime = e[0];
          this.addinfo.endTime = e[1];
        }
      }
    },
    // 新人送时间
    time_all3(e) {
      if (e !== null) {
        if (e[0] == e[1]) {
          this.$message.error('起始时间不得为同一天！');
          this.newinfo.startTime = ''
          this.newinfo.endTime = ''
        } else {
          this.newinfo.startTime = e[0];
          this.newinfo.endTime = e[1]
        }
        this.dis = true;
      } else {
        this.newinfo.startTime = '';
        this.newinfo.endTime = '';
        this.value3 = [];
        // 时间
        this.dis = false;
        this.checked = false;
      }
      this.newinfo.effectiveType = 1;
      this.newinfo.timeType = 0;
    },
    // 时间选择器
    time_all2(e) {
      let abc = '';
      let bac = '';
      let cab = '';
      if (e == null) return this.youhui.startTime = ''; this.youhui.endTime = '';
      if (e.length > 0) {
        if (e[0] == e[1]) {
          this.$message.error('起始时间不得为同一天！');
          this.youhui.startTime = ''
          this.youhui.endTime = ''
        } else {
          this.youhui.startTime = e[0];
          this.youhui.endTime = e[1];
          abc = this.youhui.startTime.split(' ')[0];
          bac = this.youhui.endTime.split(' ')[0];
          cab = this.youhui.effectiveDate.split(' ')[0];
          console.log(abc, bac, cab, '819');
          if (cab > bac || abc > cab || cab == bac) {
            this.youhui.effectiveDate = '';
            this.$message.error('指定生效时间错误，请重新选择');
          }
          if (cab == abc) return this.youhui.effectiveType = 0;
        }
      }
    },
    // 小程序充值送、新人送、场站优惠券（详情）
    async bianji(row) {
      this.current_id = row.id;
      if (row.type == 0) {
        this.title = '编辑小程序充值送';
        this.diaload = true;
        this.zhandian = false;
        await this.$http.get(`preferential/getPreferentialById/${row.id}`).then(res => {
          if (res.data.code == 0) {
            this.addinfo = res.data.data;
            this.objValue = JSON.parse(res.data.data.requirement1);
            this.value1 = [this.addinfo.startTime, this.addinfo.endTime];
            if (res.data.data.effectiveType != 0) {
              this.flag = true;
              this.icons = 'success'
            } else {
              this.icons = '';
              this.flag = false;
            };
          }
        });
      } else if (row.type == 1) {
        this.acruce = '编辑场站优惠券';
        this.diaload = false;
        this.zhandian = true;
        await this.$http.get(`preferential/getPreferentialById/${row.id}`).then(resb => {
          if (resb.data.code == 0) {
            this.youhui = resb.data.data;
            this.value2 = [this.youhui.startTime, this.youhui.endTime];
            this.objValues = JSON.parse(resb.data.data.requirement2);
            if (resb.data.data.stationIds.includes(',')) {
              this.activeStationIds = resb.data.data.stationIds.split(',').map(Number);
            } else {
              this.activeStationIds = resb.data.data.stationIds
            }
            if (resb.data.data.effectiveType != 0) {
              this.liji_flag = true;
              this.iconsss = 'success'
            } else {
              this.iconsss = '';
              this.liji_flag = false;
            };
          }
        });
      } else if (row.type == 2) {
        this.xinren = '编辑新人送';
        this.newinfo.id = row.id;
        this.tianjia_new_dialoag = true;
        await this.$http.get(`preferential/getPreferentialById/${this.newinfo.id}`).then(resb => {
          if (resb.data.code == 0) {
            this.youhui = resb.data.data;
            this.value3 = [this.youhui.startTime, this.youhui.endTime];
            this.newinfo.name = this.youhui.name;
            this.obj = JSON.parse(resb.data.data.requirement1);
            if (resb.data.data.effectiveType != 0) {
              this.liji_flag = true;
              this.iconsss = 'success'
            } else {
              this.iconsss = '';
              this.liji_flag = false;
            };
            if (this.youhui.timeType == 0) {
              this.dis = true
            }
          }
        });
      }
    },
    add_dialoag() {
      this.addinfo.type = 0;
      this.title = '添加小程序充值送';
      this.diaload = true;
      this.addinfo = {};
      this.icons = '';
      this.value1 = [];
      this.objValue = [];
      this.addinfo.requirement1 = '';
      this.flag = false;
    },
    // 添加或者编辑(小程序充值送）的确定事件
    addLogList() {
      let that = this;
      that.addinfo.status = 0;
      that.addinfo.type = 0;
      that.addinfo.timeType = 0;
      that.addinfo.requirement2 = '';
      that.addinfo.adminId = window.sessionStorage.getItem('adminId') * 1;
      that.addinfo.requirement1 = JSON.stringify(that.objValue);
      // 指定生效
      that.addinfo.effectiveType = 1;
      if (that.addinfo.name.length == 0 || that.value1.length == 0) {
        return that.$message.error('请输入小程序充值送信息')
      }
      if (that.objValue.length < 1) {
        return that.$message.error('请点击新增方案添加您的优惠方案')
      }
      // that.objValue.forEach(item => {
      //   if (item.paramKey == 0 || item.paramValue == 0) return this.$message.error('充值金额、赠送金额不得为0')
      // })
      that.$refs.addinfoRef.validate(async valid => {
        if (!valid) return
        if (that.title == '添加小程序充值送') {
          that.$http.post('preferential/addPreferential', that.addinfo).then(res => {
            if (res.data.code == 0) {
              that.$message.success('添加小程序充值送成功');
              that.getLogList();
              that.diaload = false;
              that.tianjia = false;
            } else {
              that.tianjia = false;
              that.$message.warning(res.data.msg);
            }
          })
        } else {
          that.$http.post('preferential/updPreferential', that.addinfo).then(res => {
            if (res.data.code == 0) {
              that.$message.success('编辑小程序充值送成功');
              that.getLogList();
              that.diaload = false;
              that.tianjia_chang = false;
            } else {
              that.tianjia_chang = false;
              that.$message.warning('编辑小程序充值送失败');
            }
          })
        }
        that.add_dia = false;
      })

    },
    handleClose() {
      this.diaload = false;
    },
    // 开票按钮
    async kaipiao(row) {
      if (row.type == 0) {
        this.tianjia = false;
      } else if (row.type == 1) {
        this.tianjia_chang = false
      };
      const { data: res } = await this.$http.post(`preferential/updateStateById/${row.id}/${2}/${window.sessionStorage.getItem('adminId') * 1}/${row.type}`)
      if (res.code == 0) {
        this.$message.success('作废成功');
        this.getLogList();
      } else {
        this.$message.error('作废失败')
      }
    },
    // 停用/启用
    async tingyong(row) {
      let that = this;
      let abc = null;
      if (row.status == 0) {
        abc = 3;
      } else {
        abc = 0;
      };
      if (row.type == 0 && abc == 0) {
        that.tianjia = true;
      } else if (row.type == 1 && abc == 0) {
        that.tianjia_chang = true
      } else if (row.type == 0 && abc == 3) {
        that.tianjia = false;
      } else if (row.type == 0 && abc == 3) {
        that.tianjia = false;
      };
      that.queryInfo.adminId = Number(that.queryInfo.adminId);
      that.$http.post(`preferential/updateStateById/${row.id}/${abc}/${Number(window.sessionStorage.getItem('adminId'))}/${row.type}`).then(res => {
        if (res.data.code == 0) {
          row.status = 0;
          that.getLogList();
        } else {
          row.status = 3;
          that.$message.error(res.data.msg)
        }
      })
    },
    clickall(row) {
      // console.log(row,'227')
    },
    changeTime(value) {
      this.queryInfo.startTime = value[0];
      this.queryInfo.endTime = value[1];
      if (this.queryInfo.startTime == this.queryInfo.endTime) {
        this.$message.error('起始时间不得为同一天！');
        this.queryInfo.startTime = ''
        this.queryInfo.endTime = ''
      }
    },
    // 根据分页获取对应的日志列表
    async getLogList() {
      let that = this;
      that.logList = [];
      const { data: res } = await that.$http.get('preferential/getAllPreferential', { params: that.queryInfo })
      if (res.code == 0) {
        that.logList = res.data;
        // that.logList.forEach(item => {

        // })
        this.total = res.count;
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getLogList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getLogList()
    },
    // 批量删除的复选框改变事件
    // handleSelectionChange(value) {
    //   if (value.length > 0) {
    //     this.ids = ''
    //     value.forEach(item => {
    //       this.ids += item.id + ','
    //     })
    //     this.ids = this.ids.substring(0, this.ids.length - 1)
    //   } else {
    //     return false
    //   }
    // },
    // 删除日志
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') return this.$message.info('已经取消删除！');
      if (row.id == null || row.id == '' || row.id == undefined) {
        const { data: res } = await this.$http.post('operationLog/delAdminOperationLogById/' + this.ids)
        if (res.code !== 0) {
          return this.$message.error('删除日志失败！')
        }
        this.$message.success('删除日志成功！')
        this.queryInfo.page = 1;
        this.getLogList()
      } else {
        const { data: res } = await this.$http.post('operationLog/delAdminOperationLogById/' + row.id.toString())
        if (res.code !== 0) {
          return this.$message.error('删除日志失败！')
        }
        this.$message.success('删除日志成功！')
        this.queryInfo.page = 1;
        this.getLogList()
      }
    }
  }
}
</script>

<style></style>